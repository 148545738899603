import React from "react";
import {useState,useEffect} from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BackupIcon from '@material-ui/icons/Backup';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,remove_from_array} from "../../Sources/js/app.js";
import axiosInstance from "../../Sources/js/axios.js";

const useStyles = makeStyles(theme => ({
	rootDialog: {
		height: "100vh",
	},
	alignCenter: {
		textAlign: "center",
	},
	alignRight: {
		textAlign: "right",
	},
	alignLeft: {
		textAlign: "left",
	},
	checkbox: {
		margin: "0px",
		padding: "4px",
	},
	tables: {
		marginBottom: "18px",
	},
	void_div: {
		width: "1230px",
		height: "753px",
	},
	no_margin: {
		margin: "0",
		padding: "0",
	},
	no_margin_inline: {
		margin: "0",
		padding: "0",
		display: "inline",
	}
}));

const UploadProps = {
	action: () => {
					return new Promise(resolve => {
												setTimeout(() => {
													resolve('/upload.do');
												},1000);
					});
	},
	multiple: true,
	onStart(file) {
		console.log('onStart', file, file.name);
	},
	onSuccess(ret) {
		console.log('onSuccess', ret);
	},
	onError(err) {
		console.log('onError', err);
	},
};

let oper="";

const UploadFiles = (props) => {

    if(!props.session.sessId) {
        history.push("/login");
    }

    let defaultInputValues={
		year: "2021-01-01",
		description: "",
	}
	let defaultDataValues={
		filesList: [],
	}
	let defaultDialogValues={
		openUpload: false,
		titleUpload: "",
	}
	let yearsList=[
		{
			label: "2021",
			value: "2021-01-01",
		},
	]

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [uploadFiles, setUploadFiles] = useState([]);
	const [uploadProgress, setUploadProgress] = useState();
	const [isUploadStarted, setIsUploadStarted] = useState(false);
	const [fileIsSelected, setFileIsSelected] = useState(false);
	const [completedText, setCompletedText] = useState("");

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="open_upload") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openUpload: true,titleUpload: "Incarcare fisier nou"});
			setUploadFiles([]);
			setIsUploadStarted(false);
			setUploadProgress(0);
			setFileIsSelected(false);
			setCompletedText("");
		}
		if(what==="remove_file") {
			setUploadFiles(remove_from_array(uploadFiles,elementData));
		}
		if(what==="select_file") {
			setUploadFiles(evt.target.files);
			setUploadProgress(0);
			setFileIsSelected(true);
			setIsUploadStarted(false);
			setCompletedText("");
		}
		if(what==="delete") {
			if(window.confirm("Sigur doriti stergerea?\nOperatia este ireversibila!\n\n")) {
				let url="deleteFile";
				url+="?session_id="+props.session.sessId;
				oper="delete_file";
				let data={
					id: elementData.id,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get_provider_files")||(oper==="delete_file")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,filesList: result.data});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="reload_provider_files") {
			oper="";
			setDataValues({...dataValues,filesList: result.data});
		}
		if(oper==="upload_file") {
			oper="";
			setIsUploadStarted(false);
			setUploadProgress(0);
			if(result.data.ok) {
				setUploadFiles([]);
				reloadFiles();
				setCompletedText("Fisierul a fost incarcat cu succes!");
				setInputValues({...inputValues,description: ""});
			}else{
				setCompletedText(result.data.error);				
			}
		}
	}

	const fail_handler = () => {
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const addUploadFile = (file) => {
		setUploadFiles(file);
//		setUploadErrors([]);
//		setUploadFiles(uploadFiles.concat(file));		
	}

	const uploadHandler = evt => {
		evt.preventDefault();
		oper="upload_file";
		let formData = new FormData();
		formData.append("new_file",uploadFiles[0]);
		formData.append("year",inputValues.year);
		formData.append("description",inputValues.description);
		setIsUploadStarted(true);
		setCompletedText("Va rugam asteptati...");
		axiosInstance.post("/uploadFile?session_id="+props.session.sessId,formData, {
			headers: {
						"Content-Type": "multipart/form-data",
			},
			onUploadProgress: data => {
				setUploadProgress(Math.round((100 * data.loaded) / data.total))
			},
		}).then((response) => {
			success_handler(response);
		},(error) => {
			fail_handler();
		});
	}

	const reloadFiles = () => {
		oper="reload_provider_files";
		let url="getProviderFiles";
		url+="?session_id="+props.session.sessId;
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);		
	}
	
	useEffect(() => {
		if(props.session.sessId) {
			oper="get_provider_files";
			let url="getProviderFiles";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const uploadDialog = () => {
		return(
			<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"md"} style={{"minHeight":"630px"}}>
				<DialogTitle>
					<div style={{"width":"723px","float":"left"}}>
						<p style={{"marginBottom":"0px","display":"inline"}}>
							{dialogValues.titleUpload}
						</p>
					</div>
					<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(evt)=>{setDialogValues({...dialogValues,openUpload:false});setUploadFiles([]);reloadFiles()}}>
						<Icon>close_icon</Icon>
					</Fab>
				</DialogTitle>
				<DialogContent style={{"minHeight":"300px"}}>
					<Grid container spacing={1} style={{"width":"804px"}}>
						<Grid item xs={12} style={{"textAlign":"center"}}>
							<input type="file" onChange={evt=>{handleButton(evt,"select_file")}}/>
						</Grid>
						{!!(fileIsSelected>0) &&
							<Grid item xs={12} style={{"textAlign":"center"}}>
								<TextField
									variant="outlined"
									margin="dense"
									name="description"
									required
									fullWidth
									multiline
									rows={3}
									label="Descrierea fisierului"
									value={inputValues.description}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>						
						}
						{!!(completedText) &&
							<Typography variant="h6" color="textPrimary" style={{"width":"630px","margin":"0 auto","marginTop":"12px","color":"#FF0000","textAlign":"center"}}>{completedText}</Typography>							
						}
						{!!((uploadFiles.length>0)&&(!isUploadStarted)) &&
							<Grid item xs={12} style={{"textAlign":"center"}}>
								<Button onClick={evt=>{uploadHandler(evt)}} color="primary" variant="contained" style={{"marginTop":"33px"}}>
									<DoneIcon/>
									Incarca fisierul pe server
								</Button>
							</Grid>						
						}
						{!!isUploadStarted &&
							<React.Fragment>
								<Box display="flex" alignItems="center" style={{"width":"786px","marginBottom":"57px","marginLeft":"15px","marginTop":"12px"}}>
									<Box width="786px" mr={1}>
										<LinearProgress variant="determinate" value={uploadProgress} style={{"height":"12px"}}/>
									</Box>
									<Box minWidth={35}>
										<Typography variant="body1" color="textPrimary">{`${Math.round(uploadProgress,)}%`}</Typography>
									</Box>
								</Box>
							</React.Fragment>
						}
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}

	const classes = useStyles();

	if(dataOk) {
		return(
			<div>
				<CssBaseline/>
				<div>
					<Grid container spacing={1} direction="row" alignItems="flex-start">
						<Grid item xs={4} style={{"textAlign":"center"}}>
							&nbsp;
						</Grid>
						<Grid item xs={4} style={{"textAlign":"center"}}>
							<TextField
								variant="outlined"
								margin="dense"
								name="year"
								required
								fullWidth
								select
								label="Selectati anul"
								SelectProps={{
									native: true,
								}}
								value={inputValues.year}
								onChange={evt =>handleChange(evt)}
								style={{"display":"none"}}
							>
								{yearsList.map(option => (
									<option key={option.value} value={option.value}>
										{option.label}
									</option>
								))}
							</TextField>
						</Grid>
						<Grid item xs={4} style={{"textAlign":"center"}}>
							<Button onClick={evt=>{handleButton(evt,"open_upload")}} color="secondary" variant="contained" style={{"marginTop":"-3px","marginBottom":"12px"}}>
								<BackupIcon style={{"marginRight":"6px"}}/>
									Incarca un fisier nou
							</Button>
						</Grid>
						{!!((dataValues.filesList)&&(dataValues.filesList.length>0)) &&
							!!dataValues.filesList && dataValues.filesList.map((file,index) => (
								<Grid item key={index} xs={6}>
									<Paper style={{"padding":"21px"}}>
										<Typography variant="body1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600"}}>
											{file.filename}
										</Typography>
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
											Data incarcarii:  {file.reg_date_parsed}
										</Typography>
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
											Stare incarcare:  {file.upload_status}
										</Typography>			
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
											Stare procesare:  {file.status}
										</Typography>							
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
											Descriere: {file.description}
										</Typography>
										{!!(file.status==="INCARCAT") &&
											<Button onClick={evt=>{handleButton(evt,"delete",file)}} color="secondary" variant="contained" style={{"marginTop":"12px"}}>
											<DeleteIcon style={{"marginRight":"6px"}}/>
												Sterge fisierul
											</Button>										
										}
										{!!(file.status!=="INCARCAT") &&
											<Button onClick={evt=>{handleButton(evt,"delete")}} color="secondary" variant="contained" style={{"marginTop":"12px"}} disabled={true}>
											<DeleteIcon style={{"marginRight":"6px"}}/>
												Sterge fisierul
											</Button>										
										}
									</Paper>
								</Grid>
							))
						}
						{!!((dataValues.filesList)&&(dataValues.filesList.length===0)) &&
							<Grid item xs={12} style={{"textAlign":"center","marginTop":"12px"}}>
								<Typography variant="h6" color="inherit" noWrap>
									Nu exista fisiere incarcate.
								</Typography>
							</Grid>
						}
					</Grid>
				</div>				
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openUpload && uploadDialog()}
			</div>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default UploadFiles;

