import React from "react";
import {useState,useEffect} from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import {makeStyles} from '@material-ui/core/styles';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import LinearProgress from '@material-ui/core/LinearProgress';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,find_element_in_array} from "../../Sources/js/app.js";

const useStyles = makeStyles(theme => ({
	rootDialog: {
		height: "100vh",
	},
	alignCenter: {
		textAlign: "center",
	},
	alignRight: {
		textAlign: "right",
	},
	alignLeft: {
		textAlign: "left",
	},
	checkbox: {
		margin: "0px",
		padding: "4px",
	},
	tables: {
		marginBottom: "18px",
	},
	void_div: {
		width: "1230px",
		height: "753px",
	},
	no_margin: {
		margin: "0",
		padding: "0",
	},
	no_margin_inline: {
		margin: "0",
		padding: "0",
		display: "inline",
	}
}));

let oper="";

const ProviderData = (props) => {

    if(!props.session.sessId) {
        history.push("/login");
    }

    let defaultInputValues={
		id: (props.userId?props.userId:null),
		password: "",
		confirm_password: "",
		name: "",
		address: "",
		contact_person: "",
		phone: "",
		email: "",
	}
	let defaultDataValues={
		providersList: [],
	}
	let defaultSelectedElements={
		providersTable: null,
	}
	let defaultDialogValues={
		openAddEdit: false,
		titleAddEdit: "",
		openChangePassword: false,
		titleChangePassword: "Schimbare parola",
	}

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [selectedElements, setSelectedElements] = useState(defaultSelectedElements);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="password") {
			setInputValues({...inputValues,id: elementData.id,password: "",confirm_password: ""});
			setDialogValues({...dialogValues,openChangePassword: true});
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Editare furnizor "+elementData.name});
		}
		if(what==="save") {
			if(inputValues.name==="") {
				setMessageText("Eroare: Completati campul Nume!");				
				return false;
			}
			if(inputValues.fiscal_no==="") {
				setMessageText("Eroare: Completati campul Cod fiscal!");				
				return false;
			}
			if(inputValues.role==="") {
				setMessageText("Eroare: Completati campul Tip furnizor!");				
				return false;
			}
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveProviderData";
			url+="?session_id="+props.session.sessId;
			oper="save_provider_data";
			let data=inputValues;
			data.user_name=(inputValues.provider_type_mnemonic.length>1?inputValues.provider_type_mnemonic+inputValues.fiscal_no:find_element_in_array(props.providersTypesList,inputValues.providers_types_id).mnemonic+inputValues.fiscal_no);
			data.role="furnizor";
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="save_password") {
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveProviderPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_password";
			let data=inputValues;
			fetch_url_post(url,data,success_handler,fail_handler);
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get_provider_data")||(oper==="save_provider_data")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,providersList:result.data});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_password") {
			setDialogValues(defaultDialogValues);
			setMessageText("Parola a fost schimbata cu succes!");
			oper="";
		}
	}

	const fail_handler = () => {
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}
	
	useEffect(() => {
		if(props.session.sessId) {
			oper="get_provider_data";
			let url="getProviderData";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const tableProvidersColTemplate = (row_data,column) => {
		if(column.header==="") {
			return(
				<span>
					<Button onClick={evt=>{handleButton(evt,"edit",row_data)}} color="primary" variant="contained" style={{"marginRight":"3px","display":"none"}}>
						<EditIcon style={{"marginRight":"3px"}}/>
							Editare
					</Button>
					<Button onClick={evt=>{handleButton(evt,"password",row_data)}} color="primary" variant="contained">
						<VpnKeyIcon style={{"marginRight":"3px"}}/>
							Parola
					</Button>
				</span>
			);
		}
	}

	const addEditDialog = () => {
		return(
			<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"md"}>
				<DialogTitle>
					<div style={{"width":"930px","float":"left"}}>
						<p style={{"marginBottom":"0px","display":"inline"}}>
							{dialogValues.titleAddEdit}
						</p>
					</div>
					<Fab color="secondary" size="small" style={{"float":"right","marginTop":"-33px"}} onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}>
						<Icon>close_icon</Icon>
						</Fab>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								margin="dense"
								name="name"
								required
								fullWidth
								label="Nume furnizor"
								value={inputValues.name}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								margin="dense"
								name="contact_person"
								required
								fullWidth
								label="Persoana de contact"
								value={inputValues.contact_person}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								variant="outlined"
								margin="dense"
								name="phone"
								required
								fullWidth
								label="Telefon"
								value={inputValues.phone}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								margin="dense"
								name="email"
								required
								fullWidth
								label="Email"
								value={inputValues.email}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>	
						<Grid item xs={8}>
							<TextField
								variant="outlined"
								margin="dense"
								name="address"
								required
								fullWidth
								label="Adresa"
								value={inputValues.address}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={12} className={classes.alignCenter}>
							<React.Fragment>
								<Button onClick={evt=>{handleButton(evt,"save")}} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","marginRight":"12px"}}>
									<DoneIcon style={{"marginRight":"6px"}}/>
										Salveaza datele
								</Button>
								<Button onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","width":"201px"}}>
									<CancelPresentationIcon style={{"marginRight":"6px"}}/>
										Abandoneaza
								</Button>
							</React.Fragment>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}

	const changePasswordDialog = () => {
		return(
			<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"sm"}>
				<DialogTitle>
					<div style={{"width":"210px","float":"left"}}>
						<p style={{"marginBottom":"0px","display":"inline"}}>
							{dialogValues.titleChangePassword}
						</p>
					</div>
					<Fab color="secondary" size="small" style={{"float":"right","marginTop":"-3px"}} onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})}>
        				<Icon>close_icon</Icon>
      				</Fab>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="dense"
								name="password"
								required
								fullWidth
								label="Parola noua"
								value={inputValues.password}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="dense"
								name="confirm_password"
								required
								fullWidth
								label="Confirmare parola"
								value={inputValues.confirm_password}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={12} className={classes.alignCenter}>
							<React.Fragment>
								<Button onClick={evt=>{handleButton(evt,"save_password")}} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","marginRight":"12px","width":"201px"}}>
									<DoneIcon style={{"marginRight":"6px"}}/>
										Schimba parola
								</Button>
								<Button onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","width":"201px"}}>
									<CancelPresentationIcon style={{"marginRight":"6px"}}/>
										Abandoneaza
								</Button>
							</React.Fragment>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}

	const classes = useStyles();

	if(dataOk) {
		return(
			<div>
				<CssBaseline/>
				<div>
                    <DataTable 
                        value={dataValues.providersList}
                        scrollable={true}
                        paginator={true}
                        rows={12}
                        selectionMode="single"
                        style={{"maxWidth":"100%"}}
                        selection={selectedElements.usersTable}
                        onSelectionChange={evt=>{setSelectedElements({...selectedElements,usersTable:evt.value})}}
                    >
                        <Column field="user_name" header="Utilizator" style={{"width":"15%"}}/>
                        <Column field="name" header="Nume" style={{"width":"33%"}}/>
                        <Column field="provider_type" header="Tip furnizor" style={{"width":"18%","textAlign":"center"}}/>
                        <Column field="email" header="Email" style={{"textAlign":"center","width":"15%"}}/>
                        <Column field="" header="" body={tableProvidersColTemplate} style={{"width":"18%","textAlign":"center"}}/>
                    </DataTable>
				</div>				
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openChangePassword && changePasswordDialog()}
			</div>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default ProviderData;

