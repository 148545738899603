import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const Message = (props) => {

	const close_message=(event,reason) => {
		if(reason==="clickaway") {
			return;
		}
        props.closeHandler();
    }

    return(
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            open={true}
            autoHideDuration={6000}
            onClose={close_message}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{props.text}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={close_message}
                >
                <CloseIcon />
                </IconButton>
            ]}
        />
    );
};

export default Message;