import React from 'react';
import {useState,useEffect} from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LinearProgress from '@material-ui/core/LinearProgress';
import history from "../Sources/js/history.js";
import MenuIcons from "./MenuIcons.js";
import Users from "./Sectiuni/Users.js";
import Providers from "./Sectiuni/Providers.js";
import ProviderData from "./Sectiuni/ProviderData.js";
import UploadFiles from "./Sectiuni/UploadFiles.js";
import ProvidersFiles from "./Sectiuni/ProvidersFiles.js";
import {fetch_url_post} from "../Sources/js/app.js";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
    	zIndex: theme.zIndex.drawer + 1,
    	transition: theme.transitions.create(['width', 'margin'], {
																	easing: theme.transitions.easing.sharp,
																	duration: theme.transitions.duration.leavingScreen,
																}
											),
  	},
	appBarShift: {
		marginLeft: drawerWidth,
    				width: `calc(100% - ${drawerWidth}px)`,
    				transition: theme.transitions.create(['width', 'margin'], {
      																			easing: theme.transitions.easing.sharp,
      																			duration: theme.transitions.duration.enteringScreen,
																			}
														),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 0,
	},
	flexg: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
														easing: theme.transitions.easing.sharp,
														duration: theme.transitions.duration.enteringScreen,
													}
											),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
														easing: theme.transitions.easing.sharp,
														duration: theme.transitions.duration.leavingScreen,
													}
											),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
										width: theme.spacing(9),
									},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(0),
		paddignLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
    	height: 240,
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

let oper="";

export default function Dashboard(props) {

	if(!props.session.sessId) {
		history.push("/login");
	}

	const [open, setOpen] = React.useState(true);
	const [menuSections, setMenuSections] = React.useState([]);
	const [messageText, setMessageText] = useState(null);
	const [currentSection, setCurrentSection] = React.useState("Fisiere furnizori servicii medicale");

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if(oper==="getMenuSections") {
			setMenuSections(result.data);
		}
	}

	const fail_handler = () => {
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="getMenuSections";
			let url="getMenuSections";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login");
		}
	},[]);
	
	const handleDrawerOpen = () => {
    	setOpen(true);
	};
	
	const handleDrawerClose = () => {
    	setOpen(false);
	};

	const handleSection = (evt,section) => {
		setCurrentSection(section);
		if(section==="Utilizatori") {
			history.push("/users")
		}
		if(section==="Furnizori") {
			history.push("/providers")
		}
		if(section==="Date furnizor") {
			history.push("/providerData")
		}
		if(section==="Incarcare fisiere") {
			history.push("/uploadFiles")
		}
		if(section==="Fisiere furnizori") {
			history.push("/providersFiles")
		}
	}

	const getCurrentSection = (section) => {
		if(!section) {
			return(
				<p></p>
			)
		}
		if(section==="Users") {
			return(
				<Users session={props.session} providersTypesList={props.providersTypesList}/>
			)
		}
		if(section=="Providers") {
			return(
				<Providers session={props.session} providersTypesList={props.providersTypesList}/>
			)
		}
		if(section==="ProviderData") {
			return(
				<ProviderData session={props.session} providersTypesList={props.providersTypesList}/>
			)
		}
		if(section==="UploadFiles") {
			return(
				<UploadFiles session={props.session} providersTypesList={props.providersTypesList}/>
			)
		}
		if(section==="ProvidersFiles") {
			return(
				<ProvidersFiles session={props.session} providersTypesList={props.providersTypesList}/>
			)
		}
	}

	const classes = useStyles();

	return(
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="Open drawer"
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" color="inherit" noWrap>
						{currentSection}
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
				}}
				open={open}
			>
				<div className={classes.toolbarIcon}>
					<Typography variant="h6" color="inherit" noWrap style={{"marginRight":"18%"}}>
						<span>Meniu</span>
					</Typography>						
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				{!!menuSections.length>0 &&
					<List>
						<div>
							{props.session.sessId && menuSections?.map((menuSection) => (
								<ListItem button key={menuSection.id} onClick={(evt)=>handleSection(evt,menuSection.name)}>
									<MenuIcons section={menuSection.name} key={menuSection.id}/>
								</ListItem>
							))}
							<div style={{"height":"60px"}}>&nbsp;</div>
							<Divider />
							<ListItem button onClick={(evt)=>props.logoutHandler(evt)}>
								<MenuIcons section={"Logout"}/>
							</ListItem>
						</div>
					</List>
				}
				{!!menuSections.length<1 &&
					<LinearProgress />
				}
				<Divider />
			</Drawer>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container} maxWidth={false}>
					{getCurrentSection(props.section)}
				</Container>
			</main>
		</div>
	);
}
