export function Crypt_pass(utl,ps) {
	if(utl.length<1) {
		return(false);
	}
	if(ps.length<1) {
		return(false);
	}else{
		var d=new Date();
		var cd=d.toDateString().split(" ")[2];
		var dps=ps+cd;
		var md5 = require('md5');
		var mdp=md5(dps);
		return mdp;
	}
}

export function fetch_post_form(data) {
	let body={};
	body.data=data;
	var post_data={
		method: "POST", // *GET, POST, PUT, DELETE, etc.
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(body), // body data type must match "Content-Type" header
	}
	console.log(post_data);
	return post_data;
}

export function fetch_url_post(url,data,success_handler,fail_handler) {
	var url_base="https://api.castl.ro/"; //productie
//	var url_base="http://api.castl.ro:3330/"; //development
	url=url_base+url;
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(res => res.json()).then(
														(result) => {
																		success_handler(result);
																	},
														(error) => {
																		fail_handler();
																	}
	)    
}

export function fetch_download(url,data,filename) {
	var url_base="https://api.castl.ro/"; //productie
//	var url_base="http://api.castl.ro:3330/"; //development
	url=url_base+url;
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(response => {
		response.blob().then(blob => {
			let url=window.URL.createObjectURL(blob);
			let a=document.createElement("a");
			a.href=url;
			a.download=filename;
			a.click();
		});
	});
}

export function add_to_array(array,new_item,index) {
	if(!array) {
		array=[];
	}
	if(index) {
		array[index]=new_item;
	}else{
		array.push(new_item);
	}
	return array;
}

export function remove_from_array(array,element,tip) {
	if(!array) {
		return [];
	}
	if(!tip) {
		array=array.filter((array_element)=>{				
			if(array_element.id===element.id) {
				return false;
			}else{
				return true;
			}
		});
	}else{
		if(tip==="id_fisa") {
			array=array.filter((array_element)=>{				
				if(array_element.id_fisa===element.id_fisa) {
					return false;
				}else{
					return true;
				}
			});			
		}
	}
	return array;
}

export function find_index_in_array(array,element,tip) {
	if(!array) {
		return -1;
	}
	if(tip) {
		if(typeof(element)==="object") {
			return array.findIndex((array_element)=>array_element[tip]==element[tip]);
		}else{
			return array.findIndex((array_element)=>array_element[tip]==element);
		}		
	}else{
		if(typeof(element)==="object") {
			return array.findIndex((array_element)=>array_element.id==element.id);
		}else{
			return array.findIndex((array_element)=>array_element.id==element);
		}		
	}
}

export function replace_element_in_array(array,find_element,replace_element) {
	if(!array) {
		return [];
	}
	var index=find_index_in_array(array,find_element);
	array[index]=replace_element;
	return array;
}

export function clone_object(object) {
	if(Array.isArray(object)) {
		return Object.assign([],object);
	}
	return Object.assign({},object);
}

export function object_is_empty(object) {
	for(var key in object) {
		if(object.hasOwnProperty(key)) {
			return false;
		}
	}
	return true;
}

export function find_element_in_array(array,find_element) {
	if(!array) {
		return {};
	}
	var index=find_index_in_array(array,find_element);
	if(index>0) {
		return array[index];
	}else{
		return null;
	}
}

export function array_diff(array_1,array_2) {
	let ret=array_1.filter((element)=>{
		for(var i=0;i<array_2.length;i++) {
//			console.log(array_2[i]);
//			console.log("compar: "+element.id+" cu "+array_2[i].id);
			if(element.id===array_2[i].id) {
//				console.log("gasit");
				return false;
			}
		}
//		console.log("negasit");
		return true;
	});
	return ret;
}

export function string_starts_with(where,what) {
	if(!where) {
		return true;
	}
	if(!what) {
		return true;
	}
	var searchPattern = new RegExp("^" + what.toUpperCase(),"i");
	if(searchPattern.test(where.toUpperCase())) {
		return true;
	}
	return false;
}

export function string_includes(where,what) {
	if(!where) {
		return true;
	}
	if(!what) {
		return true;
	}
//	console.log(where," ",what);
	return where.toUpperCase().includes(what.toUpperCase());
}

export function string_includes_exact(where,what) {
	if(!where) {
		return false;
	}
	if(!what) {
		return false;
	}
//	console.log(where," ",what);
	return where.toUpperCase().includes(what.toUpperCase());
}

export function string_includes_exact_left(where,what) {
	if(!where) {
		return false;
	}
	if(!what) {
		return true;
	}
//	console.log(where," ",what);
	return where.toUpperCase().includes(what.toUpperCase());
}

export function string_includes_exact_right(where,what) {
	if(!where) {
		return true;
	}
	if(!what) {
		return false;
	}
//	console.log(where," ",what);
	return where.toUpperCase().includes(what.toUpperCase());
}

export function element_is_in_array(array,element,array_is_string,separator) {
	if(!array) {
		return false;
	}	
	if(!element) {
		return false;
	}
	if(array_is_string) {
		array=array.split(separator);
	}
	if(array.findIndex((array_element)=>array_element===element)>-1) {
		return true;
	}
	return false;	
}

export function print_codebar(printer_ip_address,zpl_code,sessid) {
	var url_base="https://apiv3.sunmedical.ro/"; //productie
//	var url_base="https://apiv3.psiline.ro:9001/"; //dev
	let url=url_base+"ajax.php?SID="+sessid;
	let data={
		op: "tipareste_eticheta",
		printerUrl: "http://"+printer_ip_address+"/pstprnt",
		postData: zpl_code,
	};
	var post_data=fetch_post_form(data);
	fetch(url,post_data).then(res => res.json()).then(
														(result) => {
																		console.log("Print success: "+result);
																	},
														(error) => {
																		console.log("Print fail: "+error);
																	}
	)    
}

export function print_codebar_old(printer_ip_address,zpl_code) {
	var request = new XMLHttpRequest();
	var url="http://"+printer_ip_address+"/pstprnt";
	request.onload = function() {
								var status=request.status;
								var data=request.responseText;
								console.log("ZPL XHR response status: ",status,"XHR response data: ",data);
	}
	request.open("POST",url,true);
	request.send(zpl_code);
}
