import React from "react";
import {useState,useEffect} from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles} from '@material-ui/core/styles';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,find_element_in_array} from "../../Sources/js/app.js";

const useStyles = makeStyles(theme => ({
	rootDialog: {
		height: "100vh",
	},
	alignCenter: {
		textAlign: "center",
	},
	alignRight: {
		textAlign: "right",
	},
	alignLeft: {
		textAlign: "left",
	},
	checkbox: {
		margin: "0px",
		padding: "4px",
	},
	tables: {
		marginBottom: "18px",
	},
	void_div: {
		width: "1230px",
		height: "753px",
	},
	no_margin: {
		margin: "0",
		padding: "0",
	},
	no_margin_inline: {
		margin: "0",
		padding: "0",
		display: "inline",
	}
}));

let oper="";

const Users = (props) => {

    if(!props.session.sessId) {
        history.push("/login");
    }

    let defaultInputValues={
		id: (props.userId?props.userId:null),
		user_name: "",
		password: "",
		confirm_password: "",
		name: "",
		role: "",
		active: 1,
		providers_types_list: "",
	}
	let defaultDataValues={
		usersList: [],
	}
	let defaultSelectedElements={
		usersTable: null,
	}
	let defaultDialogValues={
		openAddEdit: false,
		titleAddEdit: "",
		openChangePassword: false,
		titleChangePassword: "Schimbare parola",
	}
	let userRolesList=[
		{
			label: "Administrator",
			value: "administrator",
		},
		{
			label: "Utilizator",
			value: "utilizator",
		},
	]
	let userActiveList=[
		{
			label: "DA",
			value: "1",
		},
		{
			label: "NU",
			value: "0",
		},
	]

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [selectedElements, setSelectedElements] = useState(defaultSelectedElements);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangeCheckbox = (evt,provider_type) => {
		if(evt.target.checked) {
			let tmp=inputValues.providers_types_list.split(",").filter(c => {return c!=provider_type});
			tmp.push(provider_type);
			setInputValues({...inputValues,providers_types_list:tmp.join(",")});			
		}else{
			let tmp=inputValues.providers_types_list.split(",").filter(c => {return c!=provider_type});
			setInputValues({...inputValues,providers_types_list:tmp.join(",")});			
		}
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="add") {
			setInputValues(defaultInputValues);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Adaugare utilizator"});
		}
		if(what==="password") {
			setInputValues({...inputValues,id: elementData.id,password: "",confirm_password: ""});
			setDialogValues({...dialogValues,openChangePassword: true});
		}
		if(what==="edit") {
			setInputValues(elementData);
			setDialogValues({...dialogValues,openAddEdit: true,titleAddEdit: "Editare utilizator "+elementData.name});
		}
		if(what==="delete") {
			if(window.confirm("Sigur doriti stergerea?\nOperatia este ireversibila!\n\n")) {
				let url="deleteUser";
				url+="?session_id="+props.session.sessId;
				oper="delete_user";
				let data={
					id: elementData.id,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}
		}
		if(what==="save") {
			if(inputValues.user_name==="") {
				setMessageText("Eroare: Completati campul Nume de utilizator!");				
				return false;
			}
			if(inputValues.name==="") {
				setMessageText("Eroare: Completati campul Nume si prenume!");				
				return false;
			}
			if(inputValues.role==="") {
				setMessageText("Eroare: Completati campul Tip utilizator!");				
				return false;
			}
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveUser";
			url+="?session_id="+props.session.sessId;
			oper="save_user";
			let data=inputValues;
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="save_password") {
			if(inputValues.password==="") {
				setMessageText("Eroare: Completati campul Parola!");				
				return false;
			}
			if(inputValues.confirm_password==="") {
				setMessageText("Eroare: Completati campul Confirmare parola!");				
				return false;
			}
			if(inputValues.password!==inputValues.confirm_password) {
				setMessageText("Eroare: Parolele introduse nu sunt identice!");				
				return false;
			}
			let url="saveUserPassword";
			url+="?session_id="+props.session.sessId;
			oper="save_password";
			let data=inputValues;
			fetch_url_post(url,data,success_handler,fail_handler);
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get_users")||(oper==="save_user")||(oper==="delete_user")) {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,usersList:result.data});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
		if(oper==="save_password") {
			setDialogValues(defaultDialogValues);
			setMessageText("Parola a fost schimbata cu succes!");
			oper="";
		}
		if(oper==="get_user_data") {
			setDialogValues(defaultDialogValues);
			setDataValues({...dataValues,usersList:result.data});
            if(!dataOk) {
                setDataOk(true);
            }
			oper="";
		}
	}

	const fail_handler = () => {
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}
	
	useEffect(() => {
		if(props.session.sessId) {
			oper="get_users";
			let url="getUsers";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);

	const tableUsersColTemplate = (row_data,column) => {
		if(column.header==="Activ") {
			if(row_data.active===1) {
				return(<span>DA</span>);
			}else{
				return(<span>NU</span>);
			}
		}
		if(column.header==="") {
			return(
				<span>
					{!!(row_data.user_name!=="administrator") &&
						<React.Fragment>
							<Button onClick={evt=>{handleButton(evt,"edit",row_data)}} color="primary" variant="contained" style={{"marginRight":"3px"}}>
								<EditIcon style={{"marginRight":"3px"}}/>
									Editare
							</Button>
							<Button onClick={evt=>{handleButton(evt,"delete",row_data)}} color="secondary" variant="contained" style={{"marginRight":"3px"}}>
								<DeleteIcon style={{"marginRight":"6px"}}/>
									Sterge
							</Button>
						</React.Fragment>
					}
					<Button onClick={evt=>{handleButton(evt,"password",row_data)}} color="primary" variant="contained">
						<VpnKeyIcon style={{"marginRight":"3px"}}/>
							Parola
					</Button>
				</span>
			);
		}
	}

	const addEditDialog = () => {
		if(inputValues.id) {
			return(
				<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"md"}>
					<DialogTitle>
						<div style={{"width":"930px","float":"left"}}>
							<p style={{"marginBottom":"0px","display":"inline"}}>
								{dialogValues.titleAddEdit}
							</p>
						</div>
						<Fab color="secondary" size="small" style={{"float":"right","marginTop":"-33px"}} onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}>
							<Icon>close_icon</Icon>
						</Fab>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<TextField
									variant="outlined"
									margin="dense"
									name="user_name"
									required
									fullWidth
									label="Nume de utilizator"
									value={inputValues.user_name}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									variant="outlined"
									margin="dense"
									name="name"
									required
									fullWidth
									label="Nume si prenume"
									value={inputValues.name}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									variant="outlined"
									margin="dense"
									name="role"
									required
									fullWidth
									select
									label="Tip utilizator"
									SelectProps={{
										native: true,
									}}
									value={inputValues.role}
									onChange={evt =>handleChange(evt)}
								>
									<option></option>
									{userRolesList.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={2}>
								<TextField
									variant="outlined"
									margin="dense"
									name="active"
									required
									fullWidth
									select
									label="Activ"
									SelectProps={{
										native: true,
									}}
									value={inputValues.active}
									onChange={evt =>handleChange(evt)}
								>
									{userActiveList.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>						
							<Grid item xs={12} style={{"marginTop":"12px","marginBottom":"12px"}}>
								<Typography variant="body1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600"}}>
									Tipuri de furnizori permise
								</Typography>
							</Grid>
							{props.providersTypesList.map(provider_type =>(
								<Grid item xs={4} key={provider_type.id}>
									<React.Fragment>
										<Checkbox
											size="small"
											checked={(inputValues.providers_types_list.split(",").includes(provider_type.id+"")?true:false)}
											style={{"float":"left","marginTop":"-6px"}}
											onChange={evt=>handleChangeCheckbox(evt,provider_type.id)}
										/>
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600","float":"left"}}>
											{props.providersTypesList.filter(c => {return(c.id==provider_type.id)})[0].label}
										</Typography>
									</React.Fragment>
								</Grid>								
							))}
							<Grid item xs={12} className={classes.alignCenter}>
								<React.Fragment>
									<Button onClick={evt=>{handleButton(evt,"save")}} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","marginRight":"12px"}}>
										<DoneIcon style={{"marginRight":"6px"}}/>
											Salveaza datele
									</Button>
									<Button onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","width":"201px"}}>
										<CancelPresentationIcon style={{"marginRight":"6px"}}/>
											Abandoneaza
									</Button>
								</React.Fragment>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			);
		}else{
			return(
				<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"md"}>
					<DialogTitle>
						<div style={{"width":"930px","float":"left"}}>
							<p style={{"marginBottom":"0px","display":"inline"}}>
								{dialogValues.titleAddEdit}
							</p>
						</div>
						<Fab color="secondary" size="small" style={{"float":"right","marginTop":"-33px"}} onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})}>
							<Icon>close_icon</Icon>
						  </Fab>
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={1}>
							<Grid item xs={5}>
								<TextField
									variant="outlined"
									margin="dense"
									name="user_name"
									required
									fullWidth
									label="Nume de utilizator"
									value={inputValues.user_name}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									variant="outlined"
									margin="dense"
									name="name"
									required
									fullWidth
									label="Nume si prenume"
									value={inputValues.name}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									variant="outlined"
									margin="dense"
									name="role"
									required
									fullWidth
									select
									label="Tip utilizator"
									SelectProps={{
										native: true,
									}}
									value={inputValues.role}
									onChange={evt =>handleChange(evt)}
								>
									<option></option>
									{userRolesList.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>
							<Grid item xs={5}>
								<TextField
									variant="outlined"
									margin="dense"
									name="password"
									required
									fullWidth
									label="Parola"
									value={inputValues.password}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={5}>
								<TextField
									variant="outlined"
									margin="dense"
									name="confirm_password"
									required
									fullWidth
									label="Confirmare parola"
									value={inputValues.confirm_password}
									onChange={evt =>handleChange(evt)}
								/>
							</Grid>
							<Grid item xs={2}>
								<TextField
									variant="outlined"
									margin="dense"
									name="active"
									required
									fullWidth
									select
									label="Activ"
									SelectProps={{
										native: true,
									}}
									value={inputValues.active}
									onChange={evt =>handleChange(evt)}
								>
									{userActiveList.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
							</Grid>						
							<Grid item xs={12} style={{"marginTop":"12px","marginBottom":"12px"}}>
								<Typography variant="body1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600"}}>
									Tipuri de furnizori permise
								</Typography>
							</Grid>
							{props.providersTypesList.map(provider_type =>(
								<Grid item xs={4} key={provider_type.id}>
									<React.Fragment>
										<Checkbox
											size="small"
											checked={(inputValues.providers_types_list.split(",").includes(provider_type.id+"")?true:false)}
											style={{"float":"left","marginTop":"-6px"}}
											onChange={evt=>handleChangeCheckbox(evt,provider_type.id)}
										/>
										<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600","float":"left"}}>
											{props.providersTypesList.filter(c => {return(c.id==provider_type.id)})[0].label}
										</Typography>
									</React.Fragment>
								</Grid>								
							))}
							<Grid item xs={12} className={classes.alignCenter}>
								<React.Fragment>
									<Button onClick={evt=>{handleButton(evt,"save")}} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","marginRight":"12px","width":"201px"}}>
										<DoneIcon style={{"marginRight":"6px"}}/>
											Salveaza datele
									</Button>
									<Button onClick={(evt)=>setDialogValues({...dialogValues,openAddEdit:false})} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","width":"201px"}}>
										<CancelPresentationIcon style={{"marginRight":"6px"}}/>
											Abandoneaza
									</Button>
								</React.Fragment>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			);
		}
	}

	const changePasswordDialog = () => {
		return(
			<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"md"}>
				<DialogTitle>
					<div style={{"width":"480px","float":"left"}}>
						<p style={{"marginBottom":"0px","display":"inline"}}>
							{dialogValues.titleChangePassword}
						</p>
					</div>
					<Fab color="secondary" size="small" style={{"float":"right","marginTop":"-3px"}} onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})}>
        				<Icon>close_icon</Icon>
      				</Fab>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								margin="dense"
								name="password"
								required
								fullWidth
								label="Parola noua"
								value={inputValues.password}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								variant="outlined"
								margin="dense"
								name="confirm_password"
								required
								fullWidth
								label="Confirmare parola"
								value={inputValues.confirm_password}
								onChange={evt =>handleChange(evt)}
							/>
						</Grid>
						<Grid item xs={12} className={classes.alignCenter}>
							<React.Fragment>
								<Button onClick={evt=>{handleButton(evt,"save_password")}} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","marginRight":"12px","width":"201px"}}>
									<DoneIcon style={{"marginRight":"6px"}}/>
										Schimba parola
								</Button>
								<Button onClick={(evt)=>setDialogValues({...dialogValues,openChangePassword:false})} color="primary" variant="contained" style={{"marginTop":"15px","marginBottom":"30px","width":"201px"}}>
									<CancelPresentationIcon style={{"marginRight":"6px"}}/>
										Abandoneaza
								</Button>
							</React.Fragment>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}

	const classes = useStyles();

	if(dataOk) {
		return(
			<div>
				<CssBaseline/>
				<div>
					<Grid container spacing={1} className={classes.tables}>					
                        <Grid item xs={10}>
                            &nbsp;
						</Grid>
						<Grid item xs={2}>
							<Button onClick={evt=>{handleButton(evt,"add")}} color="primary" variant="contained" fullWidth>
								<AddIcon style={{"marginRight":"6px"}}/>
								Adauga utilizator
							</Button>
						</Grid>
                    </Grid>
                    <DataTable 
                        value={dataValues.usersList}
                        scrollable={true}
                        paginator={true}
                        rows={12}
                        selectionMode="single"
                        style={{"maxWidth":"100%"}}
                        selection={selectedElements.usersTable}
                        onSelectionChange={evt=>{setSelectedElements({...selectedElements,usersTable:evt.value})}}
                    >
                        <Column field="user_name" header="Utilizator" style={{"width":"18%"}} filter={true} filterPlaceholder="Cautare dupa utilizator"/>
                        <Column field="name" header="Nume si prenume" style={{"width":"21%"}} filter={true} filterPlaceholder="Cautare dupa nume"/>
                        <Column field="active" header="Activ" body={tableUsersColTemplate} style={{"width":"6%","textAlign":"center"}} filter={true} filterPlaceholder="Cautare dupa tip"/>
                        <Column field="role" header="Tip utilizator" style={{"width":"12%","textAlign":"center"}} filter={true} filterPlaceholder="Cautare dupa rol"/>
                        <Column field="reg_date_parsed" header="Data inregistrarii" style={{"width":"12%","textAlign":"center"}}/>
                        <Column field="" header="" body={tableUsersColTemplate} style={{"width":"30%","textAlign":"center"}}/>
                    </DataTable>
				</div>				
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openAddEdit && addEditDialog()}
				{dialogValues.openChangePassword && changePasswordDialog()}
			</div>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default Users;

