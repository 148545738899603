import React from "react";
import {useState} from "react";
import {Router,Switch,Route} from "react-router-dom";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./Sources/css/App.css";
import Dashboard from "./Components/Dashboard.js";
import LoginForm from "./Components/LoginForm";
import history from "./Sources/js/history.js";

const App = (props) => {

	const [session, setSession] = useState({});
	const [providersTypesList, setProvidersTypesList] = useState([]);

	const loginHandler = (auth) => {
		setSession(auth.session);
		setProvidersTypesList(auth.providersTypesList);
		history.push("/");
	}

	const logoutHandler = () => {
		setSession({});
	}

    return(
		<Router history={history}>
			<Switch>
				<Route exact path="/">
					<Dashboard session={session} logoutHandler={logoutHandler}/>
				</Route>
				<Route path="/login">
					<LoginForm session={session} loginHandler={loginHandler}/>
				</Route>
				<Route path="/users">
					<Dashboard session={session} logoutHandler={logoutHandler} providersTypesList={providersTypesList} section="Users"/>					
				</Route>
				<Route path="/providers">
					<Dashboard session={session} logoutHandler={logoutHandler} providersTypesList={providersTypesList} section="Providers"/>					
				</Route>
				<Route path="/providerData">
					<Dashboard session={session} logoutHandler={logoutHandler} providersTypesList={providersTypesList} section="ProviderData"/>
				</Route>
				<Route path="/uploadFiles">
					<Dashboard session={session} logoutHandler={logoutHandler} providersTypesList={providersTypesList} section="UploadFiles"/>
				</Route>
				<Route path="/providersFiles">
					<Dashboard session={session} logoutHandler={logoutHandler} providersTypesList={providersTypesList} section="ProvidersFiles"/>
				</Route>
			</Switch>
		</Router>
	)
};

export default App;
