import React from "react";
import {useState,useEffect} from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {makeStyles} from '@material-ui/core/styles';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import Message from "../Helpers/Message.js";
import history from "../../Sources/js/history.js";
import {fetch_url_post,fetch_download} from "../../Sources/js/app.js";

const useStyles = makeStyles(theme => ({
	rootDialog: {
		height: "100vh",
	},
	alignCenter: {
		textAlign: "center",
	},
	alignRight: {
		textAlign: "right",
	},
	alignLeft: {
		textAlign: "left",
	},
	checkbox: {
		margin: "0px",
		padding: "4px",
	},
	tables: {
		marginBottom: "18px",
	},
	void_div: {
		width: "1230px",
		height: "753px",
	},
	no_margin: {
		margin: "0",
		padding: "0",
	},
	no_margin_inline: {
		margin: "0",
		padding: "0",
		display: "inline",
	}
}));

let oper="";

const ProvidersFiles = (props) => {

    if(!props.session.sessId) {
        history.push("/login");
    }

    let defaultInputValues={
		id: (props.userId?props.userId:null),
	}
	let defaultDataValues={
		providersList: [],
		filesList: [],
	}
	let defaultSelectedElements={
		providersTable: null,
	}
	let defaultDialogValues={
		openFiles: false,
		titleFiles: "Fisiere furnizor",
	}
    let defaultFilterValues={
		onlyWithFiles: false,
		onlyWithUnprocessedFiles: false,
	}

	const [inputValues, setInputValues] = useState(defaultInputValues);
	const [dataValues, setDataValues] = useState(defaultDataValues);
	const [messageText, setMessageText] = useState(null);
	const [dataOk, setDataOk] = useState(false);
	const [selectedElements, setSelectedElements] = useState(defaultSelectedElements);
	const [dialogValues, setDialogValues] = useState(defaultDialogValues);
	const [filterValues, setFilterValues] = useState(defaultFilterValues);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleChangeCheckbox = (evt) => {
		setFilterValues({...filterValues,[evt.target.name]:evt.target.checked});
	}

	const handleButton = (evt,what,elementData) => {
		if(what==="files") {
			let url="getProviderFilesById";
			url+="?session_id="+props.session.sessId;
			oper="get_provider_files";
			let data={
				id: elementData.id,
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="download") {
			let url="downloadFile";
			url+="?session_id="+props.session.sessId;
			let data={
				id: elementData.id,
			};
			fetch_download(url,data,elementData.filename);
		}
		if(what==="mark_processed") {
			let url="setFileStatus";
			url+="?session_id="+props.session.sessId;
			oper="set_file_status";
			let data={
				id: elementData.id,
				status: "IN PROCESARE"
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="mark_unprocessed") {
			let url="setFileStatus";
			url+="?session_id="+props.session.sessId;
			oper="set_file_status";
			let data={
				id: elementData.id,
				status: "INCARCAT"
			};
			fetch_url_post(url,data,success_handler,fail_handler);
		}
		if(what==="delete") {
			if(window.confirm("Sigur doriti stergerea?\nOperatia este ireversibila!\n\n")) {
				let url="deleteFile";
				url+="?session_id="+props.session.sessId;
				oper="delete_file";
				let data={
					id: elementData.id,
				};
				fetch_url_post(url,data,success_handler,fail_handler);
			}
		}
	}

	const success_handler = (result) => {
		if(result.ok===0) {
			setMessageText("Eroare: "+result.error);
		}
		if(result.is_auth===0) {
			history.push("/login");			
		}
		if(result.message) {
			setMessageText(result.message);			
		}
		if((oper==="get_providers")||(oper==="set_file_status")||(oper==="delete_file")) {
			setDataValues({...dataValues,providersList:result.data,filesList: []});
            if(!dataOk) {
                setDataOk(true);
            }
			setDialogValues({...dialogValues,openFiles: false});
			oper="";
		}
		if(oper==="get_provider_files") {
			setDataValues({...dataValues,filesList: result.data});
			setDialogValues({...dialogValues,openFiles: true});
			oper="";
		}
	}

	const fail_handler = () => {
		setMessageText("A aparut o problema in comunicatia cu server-ul. Va rugam sa incercati mai tarziu!");
	}

	const filterProviders = () => {
		if((filterValues.onlyWithFiles)&&(filterValues.onlyWithUnprocessedFiles)) {
			return(dataValues.providersList.filter(provider=>{return((provider.files>0)&&(provider.unprocessed_files>0))}));
		}
		if(filterValues.onlyWithFiles) {
			return(dataValues.providersList.filter(provider=>{return(provider.files>0)}));
		}
		if(filterValues.onlyWithUnprocessedFiles) {
			return(dataValues.providersList.filter(provider=>{return(provider.unprocessed_files>0)}));
		}
		return(dataValues.providersList);
	}

	useEffect(() => {
		if(props.session.sessId) {
			oper="get_providers";
			let url="getProvidersWithFilesDetails";
			url+="?session_id="+props.session.sessId;
			let data={};
			fetch_url_post(url,data,success_handler,fail_handler);
		}else{
			history.push("/login")
		}
	},[]);
	
	const tableProvidersColTemplate = (row_data,column) => {
		if(column.header==="") {
			if(row_data.files>0) {
				return(
					<span>
						<Button onClick={evt=>{handleButton(evt,"files",row_data)}} color="primary" variant="contained" style={{"marginRight":"3px"}}>
							<FileCopyIcon style={{"marginRight":"3px"}}/>
								Fisiere
						</Button>
					</span>
				);
			}else{
				return(
					<span>
						<p style={{"margin":"3px","padding":"0px"}}>&nbsp;</p>
					</span>
				)
			}
		}
	}

	const filesDialog = () => {
		return(
			<Dialog open={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={"xl"}>
				<DialogTitle>
					<div style={{"width":"1230px","float":"left"}}>
						<p style={{"marginBottom":"0px","display":"inline"}}>
							{dialogValues.titleFiles}
						</p>
					</div>
					<Fab color="secondary" size="small" style={{"float":"right"}} onClick={(evt)=>setDialogValues({...dialogValues,openFiles:false})}>
						<Icon>close_icon</Icon>
						</Fab>
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={1} style={{"marginTop":"12px","marginBottom":"30px","padding":"12px","paddingBottom":"30px"}}>
						{!!dataValues.filesList.length>0 && dataValues.filesList.map((file,index) => (
							<Grid item key={index} xs={6}>
								<Paper style={{"padding":"21px"}} elevation={6}>
									<Typography variant="body1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600"}}>
										{file.filename}
									</Typography>
									<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
										Data incarcarii:  {file.reg_date_parsed}
									</Typography>
									<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
										Stare incarcare:  {file.upload_status}
									</Typography>			
									<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
										Stare procesare:  {file.status}
									</Typography>							
									<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"left"}}>
										Descriere: {file.description}
									</Typography>
									<div style={{"textAlign":"center"}}>
										<Button onClick={evt=>{handleButton(evt,"download",file)}} color="primary" variant="contained" style={{"marginTop":"12px","marginRight":"12px"}}>
										<CloudDownloadIcon style={{"marginRight":"6px"}}/>
											Descarca
										</Button>										
										{file.status!=="IN PROCESARE" &&
											<Button onClick={evt=>{handleButton(evt,"mark_processed",file)}} color="primary" variant="contained" style={{"marginTop":"12px","marginRight":"12px"}}>
											<LockIcon style={{"marginRight":"6px"}}/>
												Marcheaza ca procesat
											</Button>										
										}
										{file.status==="IN PROCESARE" &&
											<Button onClick={evt=>{handleButton(evt,"mark_unprocessed",file)}} color="primary" variant="contained" style={{"marginTop":"12px","marginRight":"12px"}}>
											<LockOpenIcon style={{"marginRight":"6px"}}/>
												Marcheaza ca neprocesat
											</Button>										
										}
										<Button onClick={evt=>{handleButton(evt,"delete",file)}} color="secondary" variant="contained" style={{"marginTop":"12px"}}>
										<DeleteIcon style={{"marginRight":"6px"}}/>
											Sterge
										</Button>										
									</div>
								</Paper>
							</Grid>
						))}
					</Grid>
				</DialogContent>
			</Dialog>
		);
	}

	const classes = useStyles();

	if(dataOk) {
		return(
			<div>
				<CssBaseline/>
				<div>
					<Grid container spacing={1} className={classes.tables}>					
						<Grid item xs={4}>
							<React.Fragment>
								<Checkbox
									name="onlyWithFiles"
									size="small"
									checked={filterValues.onlyWithFiles}
									style={{"float":"left","marginTop":"-6px"}}
									onChange={evt=>handleChangeCheckbox(evt)}
								/>
								<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600","float":"left"}}>
									Doar cu fisiere incarcate
								</Typography>
							</React.Fragment>
						</Grid>								
						<Grid item xs={4}>
							<React.Fragment>
								<Checkbox
									name="onlyWithUnprocessedFiles"
									size="small"
									checked={filterValues.onlyWithUnprocessedFiles}
									style={{"float":"left","marginTop":"-6px"}}
									onChange={evt=>handleChangeCheckbox(evt)}
								/>
								<Typography variant="subtitle1" color="inherit" noWrap style={{"textAlign":"center","fontWeight":"600","float":"left"}}>
									Doar cu fisiere neprocesate
								</Typography>
							</React.Fragment>
						</Grid>								
                    </Grid>
                    <DataTable 
                        value={filterProviders()}
                        scrollable={true}
                        paginator={true}
                        rows={13}
                        selectionMode="single"
                        style={{"maxWidth":"100%"}}
                        selection={selectedElements.providersTable}
                        onSelectionChange={evt=>{setSelectedElements({...selectedElements,providersTable:evt.value})}}
                    >
                        <Column field="name" header="Nume" style={{"width":"36%"}} filter={true} filterPlaceholder="Cautare dupa nume" filterMatchMode="contains"/>
                        <Column field="provider_type" header="Tip furnizor" style={{"width":"24%","textAlign":"center"}} filter={true} filterPlaceholder="Cautare dupa tip" filterMatchMode="contains"/>
                        <Column field="contract_no" header="Contract" style={{"width":"6%","textAlign":"center"}} filter={true} filterPlaceholder="Numar"/>
                        <Column field="files" header="# incarcate" style={{"width":"9%"}}/>
                        <Column field="unprocessed_files" header="# neprocesate" style={{"width":"12%"}}/>
                        <Column field="" header="" style={{"width":"12%","textAlign":"center"}} body={tableProvidersColTemplate}/>
                    </DataTable>
				</div>				
				{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				{dialogValues.openFiles && filesDialog()}
			</div>
		);
	}else{
		return(
			<LinearProgress />
		);
	}
};

export default ProvidersFiles;

