import React from "react";
import {useState,useEffect} from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Message from "./Helpers/Message.js";
import {fetch_url_post,Crypt_pass,find_element_in_array} from "../Sources/js/app.js";
import { BrowserRouter } from "react-router-dom";

const useStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(http://www.casan.ro/media/banner/0d61e886d2d7056d006900e9b.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
        backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '63%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	avatar: {
		marginTop: "45%",
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

const defaultInputValues={
	utl: "",
	ps: "",
	provider_type_id: 0,
}

let oper="";
let type=1; //0 casa 1 furnizori

const LoginForm = (props) => {

	const classes = useStyles();

	const [inputValues, setInputValues] = React.useState(defaultInputValues);
	const [messageText, setMessageText] = useState(null);
	const [providersTypesList, setProvidersTypesList] = useState([]);
	const [dataOk, setDataOk] = useState(false);

	const handleChange = evt => {
		const {name, value} = evt.target;
		setInputValues({...inputValues,[name]:value});
	}

	const handleButton = (evt,what) => {
		if((inputValues["utl"].length<3)||(inputValues["ps"].length<3)) {
			setMessageText("EROARE: Completati toate campurile!");
			return false;
    	}
		oper="login";
		let data={};
		if(type===0) {
			data={
				utl: inputValues["utl"],
				mdp: Crypt_pass(inputValues["utl"],inputValues["ps"]),
			};
		}else{
			if(inputValues["provider_type_id"]===0) {
				setMessageText("EROARE: Alegeti tipul de furnizor din lista!");
				return false;				
			}
			for(var i=1;i<10;i++) {
				console.log(find_element_in_array(providersTypesList,i));
			}
			let provider_type=find_element_in_array(providersTypesList,inputValues["provider_type_id"]);
			console.log(provider_type);
			let utl=provider_type?provider_type.mnemonic+inputValues["utl"]:"MF"+inputValues["utl"];
			data={
				utl: utl,
				mdp: Crypt_pass(inputValues["utl"],inputValues["ps"]),
				provider_type_id: inputValues["provider_type_id"],
			};
		}
		fetch_url_post("login",data,success_handler,fail_handler);
	}

	const success_handler=(result)=> {
		if(result.ok===0) {
			setMessageText("EROARE: "+result.error);	
			return false;		
		}
		if(oper==="get_providers_types") {
			setProvidersTypesList(result.data);
            if(!dataOk) {
                setDataOk(true);
            }
		}
		if(oper==="login") {
			if(result.is_auth===1) {
				result.providersTypesList=providersTypesList;
				props.loginHandler(result);
			}else{
				setMessageText("EROARE: Autentificare esuata!");
				return false;			
			}
			oper="";
		}
	}

	const fail_handler=()=>{
		setMessageText("A aparut o problema cu conexiunea la server. Va rugam incercati mai tarziu.");
		return false;			
	}

	useEffect(() => {
		oper="get_providers_types";
		let url="getProvidersTypes";
		let data={};
		fetch_url_post(url,data,success_handler,fail_handler);
	},[]);

	if(dataOk) {
		if(type===0) {
			return(
				<Grid container className={classes.root}>
					<CssBaseline />
					<Grid item xs={12} sm={5} md={5} lg={4} xl={3} component={Paper} elevation={6} square>
						<div className={classes.paper}>
							<Avatar className={classes.avatar}>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h5">
								Autentificare
							</Typography>
							<div className={classes.form}>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									required
									name="utl"
									label="Utilizator"
									autoFocus
									onChange={evt => handleChange(evt)}
								/>
								<br/>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									required
									name="ps"
									label="Parola"
									type="password"
									onChange={evt => handleChange(evt)}
								/>
								<br/>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={evt => handleButton(evt)}
								>
									Autentificare
								</Button>
							</div>
						</div>
					</Grid>
					<Grid item xs={false} sm={7} md={7} lg={8} xl={9} className={classes.image} />
					{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				</Grid>
			);
		}else{
			return(
				<Grid container className={classes.root}>
					<CssBaseline />
					<Grid item xs={12} sm={5} md={5} lg={4} xl={3} component={Paper} elevation={6} square>
						<div className={classes.paper}>
							<Avatar className={classes.avatar}>
								<LockOutlinedIcon />
							</Avatar>
							<Typography component="h1" variant="h6" style={{"textAlign":"center"}}>
								Autentificare incarcare fisiere Casa Judeteana de Asigurari de Sanatate TULCEA
							</Typography>
							<div className={classes.form}>
								<TextField
									variant="outlined"
									margin="dense"
									name="provider_type_id"
									required
									fullWidth
									select
									label="Tip furnizor"
									SelectProps={{
										native: true,
									}}
									value={inputValues.provider_type_id}
									onChange={evt =>handleChange(evt)}
								>
									<option value="0"></option>
									{providersTypesList && providersTypesList.map(option => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</TextField>
								<br/>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									required
									name="utl"
									label="CUI"
									autoFocus
									onChange={evt => handleChange(evt)}
								/>
								<br/>
								<TextField
									fullWidth
									variant="outlined"
									margin="dense"
									required
									name="ps"
									label="Parola"
									type="password"
									onChange={evt => handleChange(evt)}
								/>
								<br/>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									className={classes.submit}
									onClick={evt => handleButton(evt)}
								>
									Autentificare
								</Button>
							</div>
						</div>
					</Grid>
					<Grid item xs={false} sm={7} md={7} lg={8} xl={9} className={classes.image} />
					{messageText && <Message text={messageText} closeHandler={()=>setMessageText(null)}/>}
				</Grid>
			);
		}
	}else{
		return(
			<div>
			</div>			
		);
	}
};

export default LoginForm;
