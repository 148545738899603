import React from "react";
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BackupIcon from '@material-ui/icons/Backup';

export default function MenuIcons(props) {

	const getIcon = (section) => {
		if(section==="Furnizori") {
			return(
					<LocalHospitalIcon />									
			);
		}
		if(section==="Fisiere furnizori") {
			return(
					<AccountBalanceWalletIcon />									
			);
		}
		if(section==="Utilizatori") {
			return(
					<HowToRegIcon />									
			);
		}
		if(section==="Date furnizor") {
			return(
					<AccountBalanceIcon />									
			);
		}
		if(section==="Incarcare fisiere") {
			return(
					<BackupIcon />									
			);
		}
		if(section==="Logout") {
			return(
					<PowerSettingsNewIcon />
			);
		}
	}

	return(
		<React.Fragment>
			<ListItemIcon>
				{getIcon(props.section)}
			</ListItemIcon>
			<ListItemText primary={props.section} />
		</React.Fragment>		
	)
}
